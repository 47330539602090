

















































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import UiButton from '@/components/ui/UiButton.vue';
import UiCheckbox from '@/components/ui/UiCheckbox.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';
import UiTruncateText from '@/components/ui/UiTruncateText.vue';

@Component({ components: { UiCheckbox, UiTextEdit, UiTruncateText, UiButton, UiSelect2 } })
export default class UiAdvancedDropdown extends Vue {
  @Prop()
  public readonly label?: string;

  @Prop()
  public readonly value?: any[] | any;

  @Prop({ required: true })
  public readonly items!: any[];

  @Prop({ default: 'left' })
  public readonly align!: 'left' | 'right';

  @Prop({ default: () => [] })
  public readonly connections!: any[];

  @Prop({ default: '' })
  public readonly connectionId!: string;

  @Prop({ default: true })
  public readonly active!: boolean;

  @Prop({ default: true })
  public readonly clearable!: boolean;

  public dropdownPositionClass = 'tw-top-full';

  public dropdownPositionStyle: { position: string; left?: string; right?: string; top?: string; bottom?: string } = {
    position: 'fixed',
    left: '0px',
    top: '100%',
  };

  public calcDropdownPosition() {
    const rect = (this.$refs.selectContainerParent as HTMLElement)?.getBoundingClientRect();
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
    console.log(rect.top, viewportHeight);

    const style = {} as any;

    rect.top > viewportHeight / 2
      ? (style.bottom = `${window.innerHeight - rect.top}px`)
      : (style.top = `${rect.bottom}px`);
    this.align === 'left' ? (style.left = `${rect.left}px`) : (style.right = `${window.innerWidth - rect.right}px`);

    this.dropdownPositionStyle = style;
  }

  public showMenu = false;
  public search = '';
  public selectedView = 'settings';

  public onSelect(value: any) {
    if (value.disabled) {
      return;
    }
    this.$emit('input', value);
  }

  public async setOpen(value: boolean) {
    this.showMenu = value;
  }

  public onFocus() {
    this.showMenu = true;
    this.calcDropdownPosition();
  }

  public getTabClass(id: string) {
    if (id === this.selectedView) {
      return 'tw-border-primary-300 tw-whitespace-nowrap tw-pb-1 tw-px-1 tw-border-b-2 tw-uppercase tw-text-neutral-400 tw-text-xs tw-font-bold';
    } else {
      return 'tw-border-transparent hover:tw-text-neutral-400 hover:tw-border-gray-300 tw-whitespace-nowrap tw-pb-1 tw-px-1 tw-border-b-2 tw-uppercase tw-text-neutral-300 tw-text-xs tw-font-medium';
    }
  }

  public get transitionName() {
    return this.selectedView === 'settings' ? 'slide-right' : 'slide-left';
  }

  onConnectionSelected(value: any) {
    // this.connectionId = value;
    this.$emit('connectionSelected', value);
  }

  handleOutsideFocus(event: FocusEvent) {
    // Check if the focus event occurred outside of the component
    if (!(this.$refs.selectContainerParent as HTMLElement)?.contains(event.target as Node)) {
      this.setOpen(false);
    }
  }

  handleOutsideClick(event: MouseEvent) {
    // Check if the click event occurred outside of the component
    if (!(this.$refs.selectContainerParent as HTMLElement)?.contains(event.target as Node)) {
      this.setOpen(false);
    }
  }

  public get filteredItems() {
    return this.items.filter((item) => {
      return item.accountingConnectionId === this.connectionId;
    });
  }

  mounted() {
    // Add a focusin/click event listener to the document
    document.addEventListener('focusin', this.handleOutsideFocus);
    document.addEventListener('click', this.handleOutsideClick);
    if (!this.connectionId && this.connections.length > 0) {
      this.onConnectionSelected(this.connections[0].id);
      this.selectedView = 'category';
    }
    if (this.connectionId) {
      this.selectedView = 'category';
    }
  }

  beforeUnmount() {
    // Remove the focusin/click event listener when the component is destroyed
    document.removeEventListener('focusin', this.handleOutsideFocus);
    document.removeEventListener('click', this.handleOutsideClick);
  }

  @Watch('showMenu')
  onMenuOpenChanged(newVal?: boolean) {
    this.$emit('open', newVal);
  }
}
