














































































































































import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { Wallet } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiTooltip from '@/components/ui/UiTooltip.vue';
import { TxnSummaryMainRecord } from '@/models/txnsSummary';
import { stringifyParams } from '@/utils/endpointUrlUtil';
import numberUtils from '@/utils/numberUtils';

type FiatCurrency = { name: string; symbol: string };

@Component({
  components: {
    UiDataTable,
    UiDropdown,
    UiTooltip,
  },
})
export default class TxnSummaryMain extends BaseVue {
  @Prop({ default: false }) isLoading?: boolean;
  @Prop({ default: [] }) items!: TxnSummaryMainRecord[];
  @Prop() filters!: Record<string, unknown>;
  @Prop() wallets!: Wallet[];
  @Prop() gridActions!: { value: string; label: string }[];
  @Prop({ default: [] }) assets!: { assetId: string; assetName: string }[];

  public numberFormat = numberUtils.format;

  public get headers() {
    return [
      {
        id: 'wallet',
        label: 'Wallet Name',
        defaultVisibility: true,
        sortable: true,
      },
      {
        id: 'interactingAddressesCount',
        label: 'Interacting Addresses',
        defaultVisibility: true,
        // textAlignment: 'right',
        sortable: true,
        filterable: true,
        rangeFilter: true,
      },
      // Deposits / Inflows
      {
        id: 'depositsTxnsCount',
        label: 'Total Count',
        groupLabel: 'Inflow Transaction Lines',
        defaultVisibility: true,
        // textAlignment: 'right',
        sortable: true,
        filterable: true,
        rangeFilter: true,
      },
      {
        id: 'depositsUncategorized',
        label: `Uncategorized Count`,
        groupLabel: 'Inflow Transaction Lines',
        defaultVisibility: true,
        defaultWidth: '148px',
        // textAlignment: 'right',
        sortable: true,
        filterable: true,
        rangeFilter: true,
      },
      {
        id: 'depositsFmv',
        label: `Estimated Value (${this.currentFiat.name})`,
        groupLabel: 'Inflow Transaction Lines',
        defaultVisibility: true,
        defaultWidth: '172px',
        // textAlignment: 'right',
        sortable: true,
        filterable: true,
        rangeFilter: true,
      },
      // Withdrawals / Outflows
      {
        id: 'withdrawalsTxnsCount',
        label: 'Total Count',
        groupLabel: 'Outflow Transaction Lines',
        defaultVisibility: true,
        // textAlignment: 'right',
        sortable: true,
        filterable: true,
        rangeFilter: true,
      },
      {
        id: 'withdrawalsUncategorized',
        label: `Uncategorized Count`,
        groupLabel: 'Outflow Transaction Lines',
        defaultVisibility: true,
        defaultWidth: '148px',
        // textAlignment: 'right',
        sortable: true,
        filterable: true,
        rangeFilter: true,
      },
      {
        id: 'withdrawalsFmv',
        label: `Estimated Value (${this.currentFiat.name})`,
        groupLabel: 'Outflow Transaction Lines',
        defaultVisibility: true,
        defaultWidth: '172px',
        // textAlignment: 'right',
        sortable: true,
        filterable: true,
        rangeFilter: true,
      },
      // Totals
      {
        id: 'totalTxnsCount',
        label: 'Total Txn. Count',
        defaultVisibility: true,
        // textAlignment: 'right',
        sortable: true,
        filterable: true,
        rangeFilter: true,
      },
      {
        id: 'netFmv',
        label: `Estimated NET Value (${this.currentFiat.name})`,
        defaultVisibility: true,
        // textAlignment: 'right',
        sortable: true,
        filterable: true,
        rangeFilter: true,
      },
    ];
  }

  public get currentFiat(): FiatCurrency {
    const baseCurrency = this.$store.state.currentOrg.baseCurrency ?? 'USDa';
    return (
      this.$store.getters['fiats/FIATS']?.find((fiat: FiatCurrency) => fiat.name === baseCurrency) ?? {
        name: baseCurrency,
        symbol: '$',
      }
    );
  }

  public getPathToTxns(item: TxnSummaryMainRecord, column: string) {
    const path = '/transactions';

    const uncategorized = column.match(/[Uu]ncategorized/);
    const params: any = {
      txFilter: {
        walletsFilter: [item.walletId],
        categorizationFilter: uncategorized ? 'Uncategorized' : 'All',
      },
      filters: {},
    };

    const direction = column.match(/deposit|withdrawal/);
    if (direction) {
      // not sure if totally accurate, Transfers and Trades could belong to either inflow or outflow
      params.filters.transactionType = direction[0] === 'deposit' ? ['Receive'] : ['Send', 'ContractExecution'];
    }

    if (this.filters) {
      params.txFilter.reconciliationFilter = this.filters.reconciliationStatus;
      if (this.filters.endDate) params.txFilter.pivotDate = this.filters.endDate;

      const selectedAssets = this.filters.assetId as any[];
      if (selectedAssets && !selectedAssets.includes('All')) {
        const tickers = this.assets.filter((a) => selectedAssets.includes(a.assetId)).map((a) => a.assetName);
        if (tickers) params.filters.tickers = tickers;
      }
    }

    const queryParams = stringifyParams(params);
    return `${path}?${queryParams}`;
  }

  public get walletById() {
    return this.wallets.reduce((a, x) => {
      a[x.id as string] = x;
      return a;
    }, {} as Record<string, Wallet>);
  }

  public getWalletNameById(walletId: string) {
    return this.walletById?.[walletId]?.name;
  }

  public onActionSelect(record: TxnSummaryMainRecord, action: string) {
    this.$emit('gridAction', { wallet: record.walletId, action });
  }
}
