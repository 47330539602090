/**
 * A Promise that can be canceled.
 * the use case is when you want to cancel a promise that is still pending
 * The promise will still resolve but with the canceled flag set to true
 * you can use this to ignore the result of the promise
 *
 */

export class CanceledError extends Error {
  public canceled = true;
  constructor() {
    super('Promise was canceled');
  }
}

export interface CancelablePromise<T> extends Promise<T> {
  cancel(): void;
}

export function makeCancelable<T>(promise: Promise<T>): CancelablePromise<T> {
  let hasCanceled = false;

  const wrappedPromise = new Promise<T>((resolve, reject) => {
    promise.then(
      (val) => (hasCanceled ? reject(new CanceledError()) : resolve(val)),
      (error) => (hasCanceled ? reject(new CanceledError()) : reject(error))
    );
  }) as CancelablePromise<T>;

  wrappedPromise.cancel = () => {
    hasCanceled = true;
  };

  return wrappedPromise;
}
