var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex-grow tw-overflow-hidden"},[_c('ui-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"isLoading":_vm.isLoading,"showActionColumn":_vm.checkScope(_vm.scopeLiterals.RulesCreate) && _vm.checkFeatureFlag('rules-modal-2'),"actionColumnWidth":"110px","fixed-table":"","show-group-headers":"","resizeable-columns":"","striped":"","no-data-message":"There are no data to display.","className":"tw-flex-grow tw-font-normal tw-h-full tw-border-b tw-border-300","tbodyClassName":"tw-divide-double tw-divide-gray-300 tw-divide-y tw-text-gray-500","headerVerticalAlignment":"bottom"},on:{"filter":function($event){return _vm.$emit('filter', $event)},"sort":function($event){return _vm.$emit('sort', $event)}},scopedSlots:_vm._u([{key:"td-wallet",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-overflow-hidden tw-overflow-ellipsis tw-whitespace-nowrap cell"},[_vm._v(" "+_vm._s(_vm.getWalletNameById(item.walletId))+" ")])])]}},{key:"td-interactingAddressesCount",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col tw-items-end cell"},[_c('ui-tooltip',{attrs:{"text":"View interacting addresses","position":"right"}},[_c('a',{staticClass:"hover:tw-underline tw-text-primary-400 tw-leading-relaxed",on:{"click":function($event){return _vm.$emit('addressClick', item.walletId)}}},[_vm._v(" "+_vm._s(_vm.numberFormat(item.interactingAddressesCount))+" ")])])],1)])]}},{key:"td-depositsTxnsCount",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"group-border-l"},[_c('div',{staticClass:"tw-flex tw-flex-col tw-truncate tw-text-right cell"},[_c('router-link',{staticClass:"hover:tw-underline tw-text-primary-400",attrs:{"to":_vm.getPathToTxns(item, 'depositsTxnsCount')}},[_vm._v(" "+_vm._s(_vm.numberFormat(item.depositsTxnsCount))+" ")])],1)])]}},{key:"td-depositsUncategorized",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col tw-truncate tw-text-right cell"},[_c('router-link',{staticClass:"hover:tw-underline tw-text-tertiary-300",attrs:{"to":_vm.getPathToTxns(item, 'depositsUncategorized')}},[_vm._v(" "+_vm._s(_vm.numberFormat(item.depositsUncategorized))+" ")])],1)])]}},{key:"td-depositsFmv",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col tw-truncate tw-text-right cell"},[_vm._v(" "+_vm._s(("" + (_vm.currentFiat.symbol) + (_vm.formatCurrency(item.depositsFmv))))+" ")])])]}},{key:"td-withdrawalsTxnsCount",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"group-border-l"},[_c('div',{staticClass:"tw-flex tw-flex-col tw-truncate tw-text-right cell"},[_c('router-link',{staticClass:"hover:tw-underline tw-text-primary-400",attrs:{"to":_vm.getPathToTxns(item, 'withdrawalsTxnsCount')}},[_vm._v(" "+_vm._s(_vm.numberFormat(item.withdrawalsTxnsCount))+" ")])],1)])]}},{key:"td-withdrawalsUncategorized",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col tw-truncate tw-text-right cell"},[_c('router-link',{staticClass:"hover:tw-underline tw-text-tertiary-300",attrs:{"to":_vm.getPathToTxns(item, 'withdrawalsUncategorized')}},[_vm._v(" "+_vm._s(_vm.numberFormat(item.withdrawalsUncategorized))+" ")])],1)])]}},{key:"td-withdrawalsFmv",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col tw-truncate tw-text-right cell"},[_vm._v(" "+_vm._s(("" + (_vm.currentFiat.symbol) + (_vm.formatCurrency(item.withdrawalsFmv))))+" ")])])]}},{key:"td-totalTxnsCount",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"group-border-l"},[_c('div',{staticClass:"tw-flex tw-flex-col tw-truncate tw-text-right cell"},[_c('router-link',{staticClass:"hover:tw-underline tw-text-primary-400",attrs:{"to":_vm.getPathToTxns(item, 'totalTxnsCount')}},[_vm._v(" "+_vm._s(_vm.numberFormat(item.totalTxnsCount))+" ")])],1)])]}},{key:"td-netFmv",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col tw-truncate tw-text-right cell"},[_vm._v(" "+_vm._s(("" + (_vm.currentFiat.symbol) + (_vm.formatCurrency(item.netFmv))))+" ")])])]}},{key:"td-action",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-w-full tw-justify-center cell tw-items-start"},[_c('ui-dropdown',{staticClass:"tw-cursor-pointer",attrs:{"values":_vm.gridActions,"up":index >= _vm.items.length - 2,"align":"right"},on:{"select":function($event){return _vm.onActionSelect(item, $event)}}},[_c('span',{staticClass:"hover:tw-underline tw-text-primary-400"},[_vm._v("Create Rule")])])],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }